import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { VerifyDTO } from '@meniu/data';
import { useResendVerify, useVerify } from 'apiClient/api';
import { FormTextFloating } from '@meniu/ui';
import { toast } from 'react-toastify';
import { useApp, UserActions } from 'context/global.context';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const secondsToWait = 59;

export const VerifyForm = ({ email }: { email: string }) => {
  const { mutate: verifyMutation, isLoading } = useVerify();
  const { mutate: resendMutation } = useResendVerify();
  const [{ auth, demo }, dispatch] = useApp();
  const queryclient = useQueryClient();
  const { t } = useTranslation();
  const [resend, setResend] = useState(secondsToWait);

  useEffect(() => {
    resend > 0 && setTimeout(() => setResend(resend - 1), 1000);
  }, [resend]);

  const handleResend = () => {
    resendMutation({
      email,
    });
    toast.success(t('Se ha reenviado el código de verificación a tu correo'));
    setResend(secondsToWait);
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<VerifyDTO>({ mode: 'all' });

  const doSubmit = (data: VerifyDTO) => {
    verifyMutation(
      {
        ...data,
        demo: demo,
        email,
        jwt: auth.access_token,
      },
      {
        onSuccess: (response) => {
          toast.success(t('Cuenta verificada'));
          dispatch(UserActions.setToken(response));
          queryclient.invalidateQueries(['menu']);
          queryclient.invalidateQueries(['profile-me']);
        },
        onError: () => {
          toast.error(t('Código incorrecto'));
          reset({ validationCode: '' });
        },
      }
    );
  };

  return (
    <div className="mb-2 text-center">
      <h4 className="mb-2 fw-bold">{t('Confirma tu cuenta')}</h4>
      <span className="mb-2">
        {t('Revisa el email que te mandamos a')} <b>{email}</b>{' '}
        {t('e ingresa el código')}
      </span>
      <Form onSubmit={handleSubmit(doSubmit)} className="mt-4">
        <Form.Group>
          <FormTextFloating
            placeholder={t('Ingresa el código')}
            type="text"
            inputMode="tel"
            id="verification-code-input"
            disabled={isLoading}
            isInvalid={!!errors.validationCode}
            error={
              errors.validationCode && t('Ingresa el código de verificación')
            }
            {...register('validationCode', {
              required: true,
              maxLength: 6,
              minLength: 6,
            })}
          />
          <div>
            <small className="text-muted small-xs text-start">
              {t('El email puede tardar unos instantes en llegar')}
            </small>
          </div>
          <div className="">
            {resend !== 0 ? (
              <Button
                variant="default"
                className="text-muted fw-semibold disabled pt-2 pb-1 border-0"
              >
                {t('Reenviar código en')} 00:
                {resend < 10 ? `0${resend}` : resend}
              </Button>
            ) : (
              <Button
                variant="default"
                onClick={handleResend}
                className="fw-semibold pt-2 pb-1"
              >
                {t('Reenviar código')}
              </Button>
            )}
          </div>
          <Form.Group className="d-grid mt-4">
            <Button
              type="submit"
              data-test="verify-register-modal-btn"
              variant="dark"
              className="fw-bold"
              disabled={isLoading || !isValid}
            >
              {t('Verificar')}
            </Button>
          </Form.Group>
        </Form.Group>
      </Form>
    </div>
  );
};
