// Libraries.
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

// Components.

// Icons.
import ContactUs from './modals/contact.modal';
import ChangePassword from './modals/change-password.modal';
import { ProfileEdit } from './components/ProfileEdit';
import { MyAccount } from './components/Myaccount';

export function Profile() {
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const methods = useForm({ mode: 'all' });
  return (
    <Container>
      <Row className="mt-5 gap-3 gap-lg-0">
        <Col sm="12" md="12" lg="6">
          <FormProvider {...methods}>
            <ProfileEdit />
          </FormProvider>
        </Col>
        <Col sm="12" md="12" lg="6">
          <MyAccount
            setOpenContactUs={setOpenContactUs}
            setOpenChangePassword={setOpenChangePassword}
          />
        </Col>
      </Row>
      <ContactUs
        openContactUs={openContactUs}
        setOpenContactUs={setOpenContactUs}
      />
      <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
      />
    </Container>
  );
}

export default Profile;
