import { Button } from 'react-bootstrap';
import { useAppState } from 'context/global.context';
import { ReactComponent as CloseIcon } from 'assets/close-outline.svg';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const initialState = {
  show: false,
  daysRemaining: 0,
  pastDue: false,
};

export const PlanInfo = () => {
  const [state, setState] = useState<{
    show: boolean;
    daysRemaining: number;
    pastDue: boolean;
  }>({ ...initialState });

  const { t } = useTranslation();

  const handleReset = () => setState({ ...initialState });

  if (!state.show) return null;

  return (
    <div className="d-flex justify-content-between bg-primary text-white text-center p-2">
      <div></div>
      <div>
        <span>
          {state.pastDue
            ? t(
                'No hemos podido hacer el cobro a tu tarjeta, revisa tu suscripción para no perder tus beneficios'
              )
            : t(
                'Tu suscripción vence en {{days}} días, perderás las funciones premium',
                { days: state.daysRemaining }
              )}
          .
        </span>{' '}
        <Link
          to="/profile/subscriptions"
          className="text-white text-decoration-none fw-semibold"
        >
          {t('Renovar ahora')}
        </Link>
      </div>
      <div>
        <Button size="sm" className="py-0 m-0" onClick={handleReset}>
          <CloseIcon stroke="white" />
        </Button>
      </div>
    </div>
  );
};
