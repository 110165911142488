// Libraries.
import { AnchorHTMLAttributes } from 'react';
import { ListGroup } from 'react-bootstrap';

// Components.
import { UserActions, useAppDispatch } from 'context/global.context';

// Icons.
import { ReactComponent as LockClosedIcon } from 'assets/lock-closed-outline.svg';
import { ReactComponent as DocumentLockIcon } from 'assets/document-lock-outline.svg';
import { ReactComponent as GridIcon } from 'assets/grid-outline.svg';
import { ReactComponent as InformationIcon } from 'assets/information-circle-outline.svg';
import { ReactComponent as MailIcon } from 'assets/mail-outline.svg';
import { ReactComponent as CartIcon } from 'assets/cart-outline.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-outline.svg';
import { Link, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { modalOptions } from 'context/menu.context';
import { ProfileCard } from './ProfileCard';

interface MeniuLink extends AnchorHTMLAttributes<any> {
  to: string;
}

const LinkMeniu = (props: MeniuLink) => (
  <a
    href={props.to}
    className={`${props.className} text-dark text-decoration-none`}
    target="_blank"
    rel="noreferrer"
  >
    {props.children}
  </a>
);

export function MyAccount({
  setOpenContactUs,
  setOpenChangePassword,
}: {
  setOpenContactUs: any;
  setOpenChangePassword: any;
}) {
  const { t } = useTranslation();

  const handleOpenChangePassword = () => setOpenChangePassword(true);

  const handleOpenContactUs = () => setOpenContactUs(true);

  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/logout');
  };

  const handleGoToSubscriptions = () => {
    navigate('subscriptions');
  };
  const dispatch = useAppDispatch();
  const handleOpenFeedback = () =>
    dispatch(UserActions.setModal(modalOptions['send-feedback']));

  return (
    <ProfileCard header={t('Mi cuenta')}>
      <ListGroup variant="flush" className="">
        <ListGroup.Item
          as={Link}
          to="/"
          className="pb-4 px-0 border-bottom text-dark"
        >
          <GridIcon className="me-2" />
          {t('Dashboard')}
        </ListGroup.Item>

        <ListGroup.Item
          as={'span'}
          className="py-4 px-0 border-bottom pointer"
          onClick={handleOpenFeedback}
        >
          <InformationIcon className="me-2" />
          <span>{t('Ayúdanos a mejorar')}</span>
        </ListGroup.Item>

        <ListGroup.Item
          className="py-4 px-0 border-bottom pointer"
          onClick={handleOpenContactUs}
        >
          <MailIcon className="me-2" />
          {t('Contactanos')}
        </ListGroup.Item>

        <ListGroup.Item
          className="py-4 px-0 border-bottom pointer"
          onClick={handleOpenChangePassword}
        >
          <LockClosedIcon className="me-2" />
          {t('Cambiar contraseña')}
        </ListGroup.Item>

        <ListGroup.Item
          className="py-4 px-0 border-bottom pointer"
          onClick={handleGoToSubscriptions}
        >
          <CartIcon className="me-2" />
          {t('Mi suscripción')}
        </ListGroup.Item>

        <ListGroup.Item
          as={LinkMeniu}
          to={`${process.env.NX_LANDING_URL_}/legal/privacy-policy`}
          className="py-4 px-0"
        >
          <DocumentLockIcon className="me-2" />
          {t('Aviso de privacidad')}
        </ListGroup.Item>
        <ListGroup.Item
          as={LinkMeniu}
          to="https://forms.gle/j5b6UzqLHJVnzgaWA"
          className="py-4 px-0"
        >
          <TrashIcon className="me-2" />
          {t('Eliminar mi cuenta')}
        </ListGroup.Item>
      </ListGroup>
    </ProfileCard>
  );
}
