/* eslint-disable @typescript-eslint/no-empty-function */
// export const emailRule = /\S+@\S+\.\S+/;
import { Menu, User, UpdateAnalyticsDto } from '@meniu/data';
import Cookies from 'js-cookie';
import { v4 } from 'uuid';
import { fetcher } from 'apiClient/fetcher';
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: Record<string, any>;
  }
}

export const trimString = (text = '', length = 16) =>
  text.length > length ? text.substring(0, length) + '...' : text;

export const mongoObjectId = function () {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const downloadFile = async ({
  name,
  url,
  suffix,
  ext = '',
  blob,
  body,
}: any) => {
  try {
    const windowsURL = window.URL || window.webkitURL;
    let newUrl = null;
    if (url) {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const blobObject = await response.blob();
      newUrl = windowsURL.createObjectURL(new Blob([blobObject]));
    } else {
      newUrl = windowsURL.createObjectURL(blob);
    }
    const link = document.createElement('a');
    link.href = newUrl;
    link.setAttribute(
      'download',
      name
      // `${name}${suffix ? `-${suffix}` : ''}.${ext}`
    );
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  } catch (error) {
    console.error({
      error,
    });
  }
};

export function sendAnalytics(event = '', identity: UpdateAnalyticsDto = {}) {
  let sessionId = Cookies.get('sessionId');
  if (!sessionId) {
    sessionId = v4();
    Cookies.set('sessionId', sessionId, { domain: '.meniuapp.com' });
  }
  if (!window.location.hostname) {
    window.dataLayer?.push({
      event,
      currency: 'USD',
      ecommerce: {
        items: [
          {
            item_name: sessionId,
          },
        ],
      },
    });
  }
  fetcher({
    url: `/aytics`,
    body: { ...identity, sessionId, event },
    method: 'POST',
  }).catch((e) => console.warn(e));
}

export const noop = () => {};

export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export class BREngine {
  user: User;
  menu?: Menu;
  constructor(user: User, menu?: Menu) {
    this.user = user;
    this.menu = menu;
  }
}
