import { FormTextFloating, PhoneControl } from '@meniu/ui';
import { emailPattern } from '@utils';
import { usePatchUser } from 'apiClient/api';
import { useApp } from 'context/global.context';
import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import ChangeLanguage from 'shared/changeLang';
import { ProfileCard } from './ProfileCard';

const UserProfileForm = ({ disableEmail }: { disableEmail: boolean }) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <Form.Group>
        <FormTextFloating
          placeholder={t('Nombre')}
          type="text"
          isInvalid={!!errors.name}
          error={errors.name && t('Ingresa tu nombre')}
          {...register('name')}
        />
      </Form.Group>

      <Form.Group>
        <FormTextFloating
          placeholder={t('Apellido')}
          type="text"
          isInvalid={!!errors.lastname}
          error={errors.lastname && t('Ingresa tu apellido')}
          {...register('lastname')}
        />
      </Form.Group>

      <Form.Group>
        <FormTextFloating
          placeholder={t('Nombre de tu negocio*')}
          type="text"
          isInvalid={!!errors.company}
          error={errors.company && t('Ingresa nombre del negocio')}
          {...register('company', { required: true })}
        />
      </Form.Group>

      <Form.Group>
        <FormTextFloating
          placeholder={t('Correo*')}
          type="text"
          disabled={disableEmail}
          isInvalid={!!errors.email}
          error={errors.email && t('Ingresa tu correo')}
          {...register('email', {
            required: true,
            pattern: emailPattern,
          })}
        />
      </Form.Group>
      <PhoneControl
        hasDefaultValue={true}
        text={{
          placeholder: t('Teléfono'),
          loading: t('Cargando'),
          error: t('Ingresa tu teléfono'),
        }}
      />
      <Form.Group>
        <ChangeLanguage className="form form-control" />
      </Form.Group>
    </>
  );
};

export function ProfileEdit() {
  const [{ user }] = useApp();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { isDirty, errors, isValid },
  } = useFormContext();

  const { mutate, isLoading } = usePatchUser();
  const doSubmit = (data: any) => {
    mutate(data, {
      onSuccess: (result) => {
        reset(result);
        toast.success('Perfil actualizado');
      },
    });
  };

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        company: user.company,
        lastname: user.lastname,
        email: user.email,
        phone: user.phone,
        code: user.code,
      });
      clearErrors();
    }
  }, [user]);

  return (
    <ProfileCard header={t('Mi perfil')}>
      <Form
        className="d-flex flex-column gap-3"
        onSubmit={handleSubmit(doSubmit)}
      >
        <UserProfileForm disableEmail={true} />
        <div className="d-grid mt-3 mb-2">
          <Button
            variant="dark"
            className="fw-bold"
            disabled={!isDirty || isLoading || !isValid}
            type="submit"
          >
            {t('Guardar cambios')}
          </Button>
        </div>
      </Form>
    </ProfileCard>
  );
}
