import { CreateMenuDto, Menu } from '@meniu/data';
import { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCreateMenu, useDuplicateMenu, useMyMenus } from 'apiClient/api';
import {
  UserActions,
  useApp,
  useAppDispatch,
  useAppState,
} from 'context/global.context';
import { FormTextFloating } from '@meniu/ui';

import { ReactComponent as RestaurantIcon } from 'assets/restaurant-outline.svg';
import { ReactComponent as CashIcon } from 'assets/cash-icon.svg';
import { ReactComponent as ChatIcon } from 'assets/chat-icon.svg';
import { ReactComponent as PeopleIcon } from 'assets/people-icon.svg';
import { ReactComponent as FacebookLogo } from 'assets/logo-facebook.svg';
import { ReactComponent as StoreIcon } from 'assets/store-icon-dark.svg';
import { ReactComponent as StarIcon } from 'assets/star.svg';
import { ReactComponent as WhatsappLogo } from 'assets/logo-whatsapp.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-outline-muted.svg';
import { ReactComponent as CopyIcon } from 'assets/copy-outline.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/google-play.svg';

import { BuyWhatsapp } from '../menu/components/orders/components/BuyWhatsapp';
import { useTranslation } from 'react-i18next';
import useVerifyPro from 'hooks/useVerifyPro';
import { DeleteMenuModal } from '../menu/modals/menu-delete.modal';
import { modalOptions } from 'context/menu.context';
import { noop } from '@utils';
import Tooltip from 'shared/tooltip';
import { ModalPaymentSuccess } from './components/ModalPaymentSucess/ModalPaymentSuccess';

const MenuName = ({
  open,
  setShowCreate,
}: {
  open: boolean;
  setShowCreate: (a: boolean) => void;
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateMenuDto>();

  const navigation = useNavigate();

  const { mutate: createMenu, isLoading } = useCreateMenu();

  const doSubmit = (data: CreateMenuDto) => {
    createMenu(data, {
      onSuccess: (response) => {
        navigation(`/menu-editor/${response._id}`);
      },
    });
  };

  const { t } = useTranslation();

  return (
    <Modal centered show={open} contentClassName="p-2" className="mobile-modal">
      <Modal.Body className="d-flex flex-column gap-3">
        <div>
          <h4 className="fw-bold mb-3">{t('Agrega una nueva sucursal')}</h4>
          <span>{t('Añade un nombre para identificar tu sucursal')}</span>
        </div>

        <Form onSubmit={handleSubmit(doSubmit)} autoComplete="false">
          <Form.Group>
            <FormTextFloating
              placeholder={t('Nombre de tu menú')}
              maxLength="40"
              error={errors.name && t('El nombre del menú es necesario')}
              {...register('name', { required: true, maxLength: 40 })}
            />
          </Form.Group>
          <div className="mt-4">
            <Button
              type="submit"
              variant="dark"
              className="fw-bold w-100 mb-2"
              disabled={isLoading}
            >
              {isLoading ? t('Creando') : t('Continuar')}
            </Button>
            <Button
              type="submit"
              variant="link"
              className="fw-bold w-100 text-dark"
              onClick={() => setShowCreate(false)}
            >
              {t('Cancelar')}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const CreateMenu = ({
  open,
  openNameModal,
}: {
  open: boolean;
  openNameModal: any;
}) => {
  const { t } = useTranslation();
  return (
    <Modal centered show={open} size="lg">
      <Modal.Body className="p-5">
        <div className="mb-4 d-flex gap-1 flex-column">
          <h4 className="fw-bold">Crea tu menú</h4>
          <span>Selecciona como quieres crear tu menú</span>
        </div>
        <div className="d-flex flex-md-row flex-column gap-3">
          <div
            onClick={openNameModal}
            className="bg-light d-flex flex-column align-items-center justify-content-center p-5 gap-2 pointer text-center rounded-4"
          >
            <span className="fw-bold ">Editor de menú</span>
            <small className="text-muted lh-sm">
              Podrás crear tu menú con nuestro editor
            </small>
          </div>
          <div className="bg-light d-flex flex-column align-items-center justify-content-center p-5 gap-2 position-relative rounded-4">
            <Badge
              bg="dark"
              className="position-absolute fw-bold"
              style={{ top: '20px', right: '20px' }}
            >
              Premium
            </Badge>
            <span className="fw-bold">Menú</span>
            <div className="d-flex flex-column">
              <small className="text-muted text-center lh-sm">
                Envíanos tu menú y nosotros lo crearemos por ti
              </small>
              <small className="text-muted text-center lh-sm">
                (Próximamente)
              </small>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MenuCard = ({
  menu,
  t,
  goToTerminal,
  goToEditor,
  total,
  goToOrders,
  handleDuplicate,
  handleDelete,
  isLoading,
}: {
  menu: Menu;
  t: any;
  goToTerminal: any;
  isLoading: boolean;
  goToEditor: any;
  total: number;
  goToOrders: any;
  handleDuplicate: (e?: string) => void;
  handleDelete: (e?: string, a?: string) => void;
}) => {
  const Status = useMemo(() => {
    if (menu.blocked)
      return (
        <div className="fw-semibold rounded-1 badge-danger badge bg-primary p-2">
          <p className="text-danger">{t('Suspendido')}</p>
        </div>
      );

    if (menu.isPublished)
      return (
        <div className="fw-semibold rounded-1 badge-success badge bg-primary p-2">
          <p className="text-success">{t('Publicado')}</p>
        </div>
      );

    return (
      <div className="fw-semibold rounded-1 badge bg-light p-2">
        <p className="text-muted">{t('No publicado')}</p>
      </div>
    );
  }, [menu.isPublished, menu.blocked]);

  return (
    <Col xl={{ span: 4 }} lg={4} className="d-flex flex-column gap-4 mt-4">
      <Card className="rounded-4 bg-white pointer border-0 custom-shadow p-4 gap-4">
        <Card.Header className="bg-white border-0 d-flex justify-content-between p-0 align-items-center">
          <div className="bg-light p-3 rounded-4">
            <RestaurantIcon width="32px" height="32px" />
          </div>
          <div>
            {!menu.blocked && (
              <Tooltip message={t('Duplicar Menú')}>
                <Button
                  className="border-0"
                  variant="default"
                  size="sm"
                  disabled={isLoading}
                  onClick={() => handleDuplicate(menu._id)}
                >
                  <CopyIcon className="text-muted" height="20px" />
                </Button>
              </Tooltip>
            )}
            <Button
              hidden={total < 2}
              className="border-0"
              variant="default"
              size="sm"
              onClick={() => handleDelete(menu._id, menu.name)}
            >
              <TrashIcon />
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column gap-2 p-0 text-start">
          <h3 className="fw-bold text-break">{menu.name}</h3>

          <div className="d-flex gap-2">
            {Status}

            {menu.enableWhatsapp ? (
              <div className="badge-success fw-semibold badge text-success rounded-2 p-2">
                {t('Menú con envíos')}
              </div>
            ) : (
              <div className="badge-success fw-semibold badge text-success rounded-2 p-2">
                {t('Menú de lectura')}
              </div>
            )}
          </div>
          <div className="border-bottom mt-3 border-light"></div>
        </Card.Body>
        <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-row gap-3">
          <Button
            className="text-dark text-decoration-none bg-light fw-semibold w-100 rounded-4"
            variant="link"
            onClick={() => goToEditor(menu._id)}
          >
            {t('Editar menú')}
          </Button>

          {menu.enableWhatsapp && (
            <Button
              className="text-dark text-decoration-none bg-light fw-semibold w-100 rounded-4"
              variant="link"
              onClick={() => goToOrders(menu._id)}
            >
              {t('Ver ordenes')}
            </Button>
          )}

          {/* <ProtectedComponent rol="user_pro">
      <Button
        className="text-dark text-decoration-none bg-light fw-bold d-none"
        variant="link"
        onClick={(e) => goToTerminal(e, menu._id)}
      >
        Terminal
      </Button>
    </ProtectedComponent> */}
        </Card.Footer>
      </Card>
    </Col>
  );
};

const Networks = () => {
  const { t } = useTranslation();
  return (
    <Card className="border-0 custom-shadow p-3 p-sm-4 w-100 rounded-4 d-flex gap-4">
      <Card.Header className="border-0 bg-white p-0">
        <div className="bg-light p-3 rounded-4 d-inline-block">
          <PeopleIcon width="32px" height="32px" />
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <div>
          <h3 className="fw-bold text-break mb-2">
            {t('Únete a nuestra comunidad')}
          </h3>
          <p className="text-muted">
            {t(
              'Estamos creando la mejor comunidad de restaurantes y cafeterías'
            )}
            .
          </p>
        </div>
        <div className="border-bottom mt-3 border-light"></div>
      </Card.Body>
      <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-row gap-3">
        <a
          href={`https://chat.whatsapp.com/Ccb46XibtER9kIXhAvGXhh`}
          target="_blank"
          className="d-flex text-decoration-none text-dark p-3 rounded bg-light justify-content-center w-100 d-flex rounded-4 fw-semibold gap-1"
          rel="noreferrer"
        >
          <WhatsappLogo className="me-1" /> Whatsapp
        </a>

        <a
          href={'https://www.facebook.com/groups/meniuapp/'}
          target="_blank"
          rel="noreferrer"
          className="d-flex text-decoration-none text-dark p-3 rounded bg-light gap-1 fw-semibold justify-content-center rounded-4 w-100 d-flex"
        >
          <FacebookLogo className="me-1" /> Facebook
        </a>
      </Card.Footer>
    </Card>
  );
};

const GoogleReview = () => {
  const { t } = useTranslation();
  return (
    <Card className="border-0 custom-shadow p-3 p-sm-4 w-100 rounded-4 d-flex gap-4">
      <Card.Header className="border-0 bg-white p-0">
        <div className="bg-light p-3 rounded-4 d-inline-block">
          <StarIcon width="32px" height="32px" />
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <div>
          <h3 className="fw-bold text-break mb-2">
            {t('¡Valóranos en Google!')}
          </h3>
          <p className="text-muted">
            {t(
              'Tu calificación en Google nos ayuda a seguir mejorando y creciendo'
            )}
            .
          </p>
        </div>
        <div className="border-bottom mt-3 border-light"></div>
      </Card.Body>
      <Card.Footer className="border-0 text-center bg-white p-0 d-flex">
        <a
          href={'https://g.page/r/CVC51jf-0q9zEAE/review'}
          target="_blank"
          rel="noreferrer"
          className="d-flex text-decoration-none text-dark p-3 rounded bg-light gap-1 fw-semibold justify-content-center rounded-4 w-100 d-flex"
        >
          {t('Escribir reseña')}
        </a>
      </Card.Footer>
    </Card>
  );
};

const MobileApp = () => {
  const { t } = useTranslation();
  return (
    <Card className="border-0 custom-shadow p-3 p-sm-4 w-100 rounded-4 d-flex gap-4">
      <Card.Header className="border-0 bg-white p-0">
        <div className="bg-light p-3 rounded-4 d-inline-block">
          <GooglePlayIcon width="32px" height="32px" />
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <div>
          <h3 className="fw-bold text-break mb-2">
            {t('Descarga nuestra App')}
          </h3>
          <p className="text-muted">
            {t('Puedes descargar nuestra App gratis en Google Play')}.
          </p>
        </div>
        <div className="border-bottom mt-3 border-light"></div>
      </Card.Body>
      <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-row gap-3">
        <a
          href={`https://play.google.com/store/apps/details?id=com.meniuapp.mobile`}
          target="_blank"
          className="d-flex text-decoration-none text-dark p-3 rounded bg-light justify-content-center w-100 d-flex rounded-4 fw-semibold gap-1"
          rel="noreferrer"
        >
          {t('Descargar App gratis')}
        </a>
      </Card.Footer>
    </Card>
  );
};

const Affiliates = () => {
  const { t } = useTranslation();
  return (
    <Card className="border-0 custom-shadow p-3 p-sm-4 w-100 d-flex gap-4 rounded-4">
      <Card.Header className="border-0 bg-white p-0">
        <div className="bg-light p-3 rounded-4 d-inline-block">
          <CashIcon width="32px" height="32px" />
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <div>
          <h3 className="fw-bold text-break mb-2">
            {t('Programa de afiliados')}
          </h3>
          <p className="text-muted">
            {t('Únete al programa y empieza a ganar dinero cada mes')}. <br />
            {t('No tiene costo, no debes pagar nada y no hay ventas mínimas')}.
          </p>
        </div>
        <div className="border-bottom mt-3 border-light"></div>
      </Card.Body>
      <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-column flex-sm-row gap-3">
        <a
          href={`https://wa.me/525521949216?text=Hola%2C%20me%20interesa%20ganar%20dinero%20con%20su%20programa%20de%20afiliados`}
          target="_blank"
          className="d-flex text-decoration-none text-dark py-3 px-2 rounded bg-light fw-semibold justify-content-center w-100 d-flex rounded-4"
          rel="noreferrer"
        >
          {t('Gana dinero')}
        </a>

        <a
          href={'https://meniuapp.com/afiliados'}
          target="_blank"
          rel="noreferrer"
          className="d-flex text-decoration-none text-dark py-3 px-2 rounded bg-light fw-semibold justify-content-center w-100 d-flex rounded-4"
        >
          {t('Quiero saber más')}
        </a>
      </Card.Footer>
    </Card>
  );
};

const FeedBack = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleOpenFeedback = () =>
    dispatch(UserActions.setModal(modalOptions['send-feedback']));

  return (
    <Card className="border-0 custom-shadow p-3 p-sm-4 w-100 rounded-4 d-flex gap-4">
      <Card.Header className="border-0 bg-white p-0">
        <div className="bg-light p-3 rounded-4 d-inline-block">
          <ChatIcon width="32px" height="32px" />
        </div>
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <div>
          <h3 className="fw-bold mb-2">{t('Ayúdanos a mejorar')}</h3>
          <p className="text-muted">
            {t('Compártenos tu feedback para mejorar nuestra App')}.
          </p>
        </div>
        <div className="border-bottom mt-3 border-light"></div>
      </Card.Body>
      <Card.Footer className="border-0 text-center bg-white p-0 d-flex flex-column flex-sm-row gap-3">
        <Button
          className="btn btn-link bg-light text-decoration-none w-100 text-dark fw-semibold rounded-4"
          onClick={handleOpenFeedback}
        >
          {t('Contestar encuesta')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

function MenusDashboard({
  menus,
  setShowCreate,
  setCurrentMenu,
}: {
  menus: Menu[];
  setShowCreate: (e: boolean) => void;
  setCurrentMenu: any;
}) {
  const { t } = useTranslation();

  const { isProModal } = useVerifyPro();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPro } = useVerifyPro();

  const { mutate: duplicateMutation, isLoading } = useDuplicateMenu();

  const goToTerminal = (menuId?: string) =>
    navigate('/menu-editor/' + menuId + '?tab=terminal');

  const goToEditor = (menuId?: string) => navigate('/menu-editor/' + menuId);

  const goToOrders = (menuId?: string) =>
    navigate(`/menu-editor/${menuId}?tab=orders`);

  const handleCreate = () => {
    isProModal({ analyticsFeaturesPro: 'multiple-menus' }) &&
      setShowCreate(true);
  };

  const handleDelete = (menuId?: string, name?: string) => {
    setCurrentMenu({ _id: menuId, name });
    dispatch(UserActions.setModal(modalOptions['delete-menu']));
  };

  const handleDuplicate = (menuId = '') => {
    isProModal({ analyticsFeaturesPro: 'clone_menu' }) &&
      duplicateMutation({ menuId });
  };

  return (
    <Container fluid className="pt-4">
      <Row>
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <h3 className="fw-bold">{t('Mis menús')}</h3>
          </div>
          <div>
            <Button
              variant="light"
              className="fw-semibold py-3 px-3 py-sm-2 px-sm-3 rounded-3 d-flex gap-2 bg-white shadow-sm border"
              onClick={handleCreate}
              hidden={!!menus.find((m) => m.blocked)}
            >
              <StoreIcon width="20px" height="20px" />
              <span className="d-none d-sm-block">{t('Agregar sucursal')}</span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="">
        {menus.map((menu, index) => (
          <MenuCard
            menu={menu}
            key={index}
            total={menus.length}
            t={t}
            goToTerminal={goToTerminal}
            goToEditor={goToEditor}
            goToOrders={goToOrders}
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
            isLoading={isLoading}
          />
        ))}

        <Col xl={{ span: 4 }} lg={4} className={'d-flex mt-4'}>
          <FeedBack />
        </Col>

        <Col xl={{ span: 4 }} lg={4} className={'d-flex mt-4'}>
          <MobileApp />
        </Col>
        <Col xl={{ span: 4 }} lg={4} className={'d-flex mt-4'}>
          <Affiliates />
        </Col>
        {!isPro && (
          <Col xl={{ span: 4 }} lg={4} className={'d-flex mt-4'}>
            <BuyWhatsapp />
          </Col>
        )}
        <Col xl={{ span: 4 }} lg={4} className={'d-flex mt-4'}>
          <Networks />
        </Col>
        <Col xl={{ span: 4 }} lg={4} className={'d-flex mt-4'}>
          <GoogleReview />
        </Col>
      </Row>
    </Container>
  );
}

function FirstTimeDashboard({ openModal }: { openModal: any }) {
  const { user } = useAppState();
  const { t } = useTranslation();
  return (
    <Container>
      <Row className="align-items-center justify-content-center flex-column">
        <div style={{ width: '480px' }} className="text-center mt-5">
          <h4 className="fw-bold mb-1">
            {t('Hola')} {user.name}, {t('bienvenido a Menui')}
            <span className="text-primary">.</span>
          </h4>
          <span>{t('Comienza a crear tu menú digital')}</span>

          <div className="d-grid">
            <Button
              onClick={() => openModal(true)}
              className="btn btn-dark fw-bold"
            >
              {t('Crear menú')}
            </Button>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export function Loader() {
  return (
    <Container fluid className="pt-4 d-flex flex-column gap-4">
      <div
        className="pulse mb-2"
        style={{ height: '40px', width: '400px' }}
      ></div>
      <Row className="lines g-4">
        {[...Array(6)].map((index, key) => (
          <Col
            key={'col_' + key}
            sm={12}
            md={12}
            lg={4}
            className="rounded-3 bg-white pulse"
            style={{ height: '312px' }}
          ></Col>
        ))}
      </Row>
    </Container>
  );
}

export function DashboardPage() {
  const [open, openModal] = useState<boolean>(false);

  const { user } = useAppState();

  const openCreateModal = () => {
    openModal(false);
    setShowCreate(true);
  };

  const [showCreate, setShowCreate] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateMenuDto>();

  const navigate = useNavigate();
  const location = useLocation();

  const { mutate: createMenu, isLoading: isLoadingCreating } = useCreateMenu();

  const doSubmit = (data: CreateMenuDto) => {
    createMenu(data, {
      onSuccess: (response) => {
        const isPaymentSuccess = location.pathname.includes('payment-success');
        if (isPaymentSuccess) {
          navigate('/payment-success');
        } else {
          navigate(`/menu-editor/${response._id}`);
        }
      },
    });
  };

  const { data: menus, isLoading } = useMyMenus({ page: 1 });

  useEffect(() => {
    if (user.isManagedUser) {
      navigate('/managed-menus');
    } else if (menus && menus?.totalDocs === 0) {
      doSubmit({ name: user.company || user.name || user.email || '' });
    }
  }, [menus]);

  const [{ modal }, dispatch] = useApp();

  const handleClose = () => {
    dispatch(UserActions.closeModal());
  };

  const [currentMenu, setCurrentMenu] = useState({ _id: '', name: '' });

  if (isLoading || isLoadingCreating) return <Loader />;

  return (
    <>
      <MenusDashboard
        menus={menus?.docs || []}
        setShowCreate={setShowCreate}
        setCurrentMenu={setCurrentMenu}
      />
      <ModalPaymentSuccess />

      <DeleteMenuModal
        menu={{ _id: currentMenu._id, name: currentMenu.name }}
        show={modal.includes(modalOptions['delete-menu']) && !!currentMenu.name}
        close={handleClose}
        openEdit={noop}
      />
      <MenuName open={showCreate} setShowCreate={setShowCreate} />
    </>
  );
}

export default DashboardPage;
