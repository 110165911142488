import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';

export function ProfileCard({
  children,
  header,
  dark,
}: {
  children: ReactNode;
  header: string;
  dark?: boolean;
}) {
  return (
    <Card
      className="border-0 w-100 shadow-sm"
      bg={dark ? 'dark' : 'white'}
      text={!dark ? 'dark' : 'white'}
    >
      <Card.Body className="p-4 pb-3 mt-2">
        <h4 className="fw-bold mb-4">{header}</h4>
        {children}
      </Card.Body>
    </Card>
  );
}
